import React from "react";
import { Card, Row, Col, CardBody } from "react-bootstrap";
import "../Styles/HomePage.css";

const ReusableCard = (props) => {
  return (
    <div>
      <Row className="mt-5">
        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">
                  Maintenance & <br></br>Utility{" "}
                </span>
                <span className="text2">Bill Payments</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container">
                  <img
                    src="./Images/Bill-Payments.png"
                    className="management-images"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Authorize and manage visitors effortlessly with this app,
                      pre-authenticate guests for a smooth entry and exit
                      experience.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">Staff & Vendor </span>
                <span className="text2">Management</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container">
                  <img
                    src="./Images/staff_and_vendor.png"
                    className="management-images"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Manage and monitor the staff members and all vendors with
                      the ease.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">Digitised </span>
                <span className="text2">Communications</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container">
                  <img
                    src="./Images/communication.png"
                    className="management-images"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Keep residents engaged and informed about society events
                      and important notices with digitised communications.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3 ">
        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">
                  Complaint <br></br>
                </span>
                <span className="text2">Management</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container">
                  <img
                    src="./Images/complaint_management.png"
                    className="management-images"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Track and resolve all residents and apartment issues.
                      Assign owners for issues and escalations for closure and
                      get faster resolutions.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">Residents & Apartments </span>
                <span className="text2">Management</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container ">
                  <img
                    src="./Images/apartment_management.png"
                    className="management-images ms-3"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Manage all society residents and apartments at one place.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12} sm={12} xl={4} className="mb-5">
          <Card className="reusable-card">
            <Card.Body>
              <Card.Title>
                <span className="title">
                  Amenities <br></br>
                </span>
                <span className="text2">Management</span>
              </Card.Title>

              <Card.Text>
                <div className="image-container">
                  <img
                    src="./Images/amenities.png"
                    className="management-images"
                    alt="Management"
                  />
                  <Card className="inner-card">
                    <CardBody className="body-text">
                      Monitor the amenities and classes from dashboard allowing
                      more control on amenity usage.
                    </CardBody>
                  </Card>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReusableCard;
