import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Styles/HomePage.css"

const Header = () => {
    const handledownload = () => {
        // window.open("https://www.onelink.to/honc","_blank")
        // const userAgent = window.navigator.userAgent.toLowerCase();
    
        // if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    
        //   window.open("https://www.onelink.to/honc", "_blank");
        // } else if (userAgent.includes('android')) {
    
        //   window.open("https://www.onelink.to/honc", "_blank");
        // } else {
    
        //   window.open("https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN", "_blank");
        // }
      }

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <section style={{ backgroundColor: "#ED2C4E" }} className=' navbar-fields overflow-hidden p-0'>
        <div className='container'>
    <Navbar  expand="lg" className=' navbar fixed-top' style={{ backgroundColor: "#ED2C4E" }}>
      <Container>
        {/* Left side - Logo */}
        <Navbar.Brand href="#home">
          <img
            src='./images/Mwahalla_logo.png' // Replace with your logo
            className='mwahalla-logo'
            alt="Logo"
          />
        </Navbar.Brand>

        {/* Navbar toggler for responsive behavior */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Right side - Contact and Team links */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto"> {/* Aligns items to the right */}
            <Nav.Link  className='nav-item-color'  onClick={() => scrollToSection("for-management")}>for Managemnt</Nav.Link>
            <Nav.Link  className='nav-item-color nav-item2'   onClick={() => scrollToSection("for-residents")}>for Residents</Nav.Link>
            <Nav.Link  className='nav-item-color nav-item2'  onClick={() => scrollToSection("contact")}>Contact</Nav.Link>
            <Nav.Link  className='nav-item-color nav-item2' >
                <div className='download d-flex'>
                <div className='d-flex justify-content-center align-items-center text-center download-btn' style={{ cursor: "pointer" }} onClick={handledownload}>

<span className='download-app' style={{color:"#ED2C4E"}} > Download App</span>



<span className='d-flex justify-content-center align-items-center'>
  <img src="./images/download_icon.png" className='ms-1 download-icons' />
</span>


</div>
                </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    </section>
  );
};

export default Header;
