import React from 'react'
import Header from '../components/Header'
import HomePage from '../components/HomePage'

const Home = () => {
  return (
    <div>
        <Header/>
        <HomePage/>
    </div>
  )
}

export default Home